
main.settings form .form-group {
    margin-top: 25px;
    position: relative;
    margin-bottom: 0px;
}
main.settings .section-right form .form-group {
    margin-top: 15px;
}

main.settings form .form-group label {
    cursor: pointer;
    font-weight: 500;
    font-size: 13px;
    color: #121926;
    margin-bottom: 3px;
    letter-spacing: .25px;
}

main.settings form .form-group label svg {
    color: #000;
    margin-right: 10px;
    margin-top: 5px;
    transition: .25s;
}

main.settings form .form-group.focus label {
    font-size: 15px;
    top: -12px;
    font-weight: 500;
    color: #042B56;
}

main.settings form .form-group.focus svg {
    color: #042B56;
}

main.settings form .form-group .input-icon {
    position: absolute;
    left: 0.7rem;
    bottom: 0.6rem;
}

main.settings form .form-group.email .input-icon {
    bottom: 0.6rem;
}

main.settings form .form-group .input-icon i {
    font-size: 18px;
    margin-top: 4px;
}
main.settings form .form-group .input-icon svg {
    font-size: 24px;
}


main.settings form .form-group input {
    color: #121926 !important;
    font-weight: 400;
    letter-spacing: .25px;
    background: #FFF !important;
    border: 1px solid #e3e8ef !important;
    border-radius: .5rem !important;
    height: 2.75rem !important;
    padding: 0.5rem 0.875rem 0.5rem 2.875rem !important;
    font-size: 15px !important;
    width: 100%;
}

main.settings form .form-group.lastname input {
    padding: 0.5rem 0.875rem !important;
}

main.settings form .form-group input::placeholder {
    font-weight: 300;
}

main.settings form .form-group.password input::placeholder {
    padding-top: 16px !important;
    letter-spacing: 1px;
}

main.settings form .nlp .form-group input {
    padding: 0.5rem 0.875rem 0.5rem 0.875rem !important;
}

main.settings form .btn-main, main.settings form .btn-main-inverse{
    font-weight: 600;
    padding: 1.15rem;
    border: 1px solid var(--main-color);
}

main.settings ._1Fq_tZ_l7SwH_r_a_SNOtA{
    font-family: 'Hanken Grotesk', sans-serif;
}

main.settings ._1Fq_tZ_l7SwH_r_a_SNOtA ._1Ou1tAFc1VCD4TNa0XwE7z {
    padding: 0%;
}

main.settings ._2oSbenaQCQZPXOD75Sj6nE {
    margin: 0;
}

main.settings .Icon--picture{
    display: none !important;
}

main.settings ._1MKAF96uXJHSW-a1nWoriQ {
    width: 100%;
    margin: 10px 0px 0px 0px;
}

main.settings ._1Fq_tZ_l7SwH_r_a_SNOtA ._10Kns8R3VdHSGFrESIz-4B{
    margin: 10px 0px 0px 0px;
}

main.settings .Icon--trash{
    fill: 'maroon';
}

._6IMSWtd3wtGGn2KgOcZIK{
    background-color: #FFF;
    padding: 10px 0px;
}