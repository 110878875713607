/* For Chrome, Safari, Edge, Opera */
.score-form input::-webkit-outer-spin-button,
.score-form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
.score-form input[type=number] {
  -moz-appearance: textfield;
  text-align: center;
}

.score-form select.form-control{
  padding: 0.35rem 0.475rem;
  font-family: 'Outfit', sans-serif;
  font-size: 14px;
}