@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.auth-screens{
    height: 100%;
    background: var(--main-color);
}

.inter {
    font-family: 'Inter', sans-serif;
}

.auth-screens .form-wrapper {
    max-width: 30rem;
    width: 100%;
}

.auth-screens .stretch-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.auth-screens .auth-bg {
    /*    background-color: #042B56;*/
    /*    background-image: url('../images/auth-bg.svg');*/
    background-position: 50% -240px;
    background-repeat: no-repeat;
    background-size: cover;
}

.auth-screens form{
    background: #FFF;
    border-radius: 20px;
    padding: 3rem;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
}

.auth-screens form h3 {
    word-wrap: break-word;
    font-size: 2.8rem;
    line-height: 1.4;
    color: var(--alt-color);
    font-weight: 600;
    letter-spacing: 2px;
    margin-bottom: 3px;
}

.auth-screens form h6 {
    letter-spacing: normal;
    font-size: 1rem;
    font-weight: 500;
    color: #000;
    margin-bottom: 30px;
}

.auth-screens form hr {
    margin: 1.5rem 0rem;
    height: 0.01rem;
    background: #e3e8ef;
}

.auth-screens form .form-group {
    margin-top: 25px;
    position: relative;
    margin-bottom: 0px;
}
.auth-screens .section-right form .form-group {
    margin-top: 15px;
}

.auth-screens form .form-group label {
    cursor: pointer;
    font-weight: 500;
    font-size: 13px;
    color: #121926;
    margin-bottom: 3px;
    letter-spacing: .25px;
}

.auth-screens form .form-group label svg {
    color: #000;
    margin-right: 10px;
    margin-top: 5px;
    transition: .25s;
}

.auth-screens form .form-group.focus label {
    font-size: 15px;
    top: -12px;
    font-weight: 500;
    color: #042B56;
}

.auth-screens form .form-group.focus svg {
    color: #042B56;
}

.auth-screens form .form-group .input-icon {
    position: absolute;
    left: 0.7rem;
    bottom: 0.6rem;
}

.auth-screens form .form-group.email .input-icon {
    bottom: 0.6rem;
}

.auth-screens form .form-group .input-icon i {
    font-size: 18px;
    margin-top: 4px;
}
.auth-screens form .form-group .input-icon svg {
    font-size: 24px;
}

.auth-screens form .remember-me {
    align-items: center;
    margin-bottom: 0;
    font-weight: 500;
    display: flex;
}

.auth-screens form .remember-me input {}

.auth-screens form .remember-me span {
    padding-left: 15px;
    display: inline-block;
}

.auth-screens .form-info_text {
    font-size: 1rem;
    letter-spacing: .25px;
    font-weight: 500;
}

.auth-screens .btn-main-alt {
    border-radius: 5rem;
    background-color: var(--alt-color);
    color: #FFF;
    transition: 350ms ease;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.06em;
    border: 1px solid var(--alt-color);
    padding: 1rem 1.125rem !important;
    text-transform: capitalize;
    line-height: 1.6;
}

.auth-screens .btn-main-alt:hover {
    box-shadow: rgb(76 78 100 / 100%) 0px 6px 18px -8px;
    background-color: #003052;
    color: #FFF;
}

@media screen and (min-width: 786px) {
    .auth-screens body {
        overflow-y: hidden;
    }
}

@media screen and (max-width: 786px) {
    .auth-screens .login {
        max-width: 100%;
    }
}

.auth-screens form .form-group .PhoneInput {
    border: 1px solid #e3e8ef !important;
    border-radius: .5rem !important;
    padding-left: 0.875rem !important;
}
.auth-screens form .form-group .PhoneInput:hover {
    border: 1px solid #042B56 !important;
}
.auth-screens form .form-group .PhoneInput.PhoneInput--focus {
    border: 1px solid #042B56 !important;
}
.auth-screens form .form-group.nb input {
    border: 0px solid #e3e8ef !important;
}

.auth-screens form .form-group input {
    color: #121926 !important;
    font-weight: 400;
    letter-spacing: .25px;
    background: #FFF !important;
    border: 1px solid #e3e8ef !important;
    border-radius: .5rem !important;
    height: 2.75rem !important;
    padding: 0.5rem 0.875rem 0.5rem 2.875rem !important;
    font-size: 15px !important;
    width: 100%;
}

.auth-screens form .form-group.lastname input {
    padding: 0.5rem 0.875rem !important;
}

.auth-screens form .form-group input::placeholder {
    font-weight: 300;
}

.auth-screens form .form-group.password input::placeholder {
    padding-top: 16px !important;
    letter-spacing: 1px;
}

.auth-screens form .nlp input {
    padding: 0.5rem 0.875rem 0.5rem 0.875rem !important;
}

.auth-screens form .form-group input:active,
.auth-screens form .form-group input:hover,
.auth-screens form .form-group input:focus,
.auth-screens form .form-group input:focus-visible{
    border: 1px solid #042B56 !important;
    outline: none;
}

.auth-screens form .form-group.phone input:active,
.auth-screens form .form-group.phone input:hover,
.auth-screens form .form-group.phone input:focus,
.auth-screens form .form-group.phone input:focus-visible{
    border: 0px solid #042B56 !important;
    outline: none;
}

/* Change the white to any color */
.auth-screens input:-webkit-autofill,
.auth-screens input:-webkit-autofill:hover,
.auth-screens input:-webkit-autofill:focus,
.auth-screens input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #000 !important;
    -webkit-box-shadow: 0 0 0 30px #FFF inset !important;
}




/* Register */

@media screen and (min-width: 768px) {
    /* .auth-screens .section-left {
        background-image: url('../../public/images/section-left.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right;
        margin-bottom: 0;
        position: relative;
    } */
}

.auth-screens .section-left {
    background: var(--main-color);
    position: relative;
}

.auth-screens .section-left .bg-overlay {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
}

.auth-screens .section-left .wave {
    height: 100%;
    position: absolute;
    right: -80px;
    z-index: 9;
}

.auth-screens .section-left-content {
    /*
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, .05);
    border: 1px solid rgba(255, 255, 255, .1);
    border-radius: 1rem;
    padding: 2rem;
*/
}

.auth-screens .section-left-content h2 {
    color: #FFF;
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 1.75;
    margin-bottom: 0px;
}

.auth-screens .section-left-content img {
    width: 14rem;
}



.auth-screens .radios {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth-screens .radios .radio {
    width: 50%;
}

.auth-screens .radio input {
    pointer-events: none;
    visibility: hidden;
}

.auth-screens .radio input:focus+label {
    background: transparent;
}

.auth-screens .radio input:focus+label .checker {
    border-color: var(--main-color);
}

.auth-screens .radio input:checked+label .checker {
    box-shadow: inset 0 0 0 4px #FFF;
}

.auth-screens .radio input:checked+label {
    background: var(--main-color-light);
    border: 1px solid var(--main-color);
    color: var(--main-color);
}

.auth-screens .radio label {
    border: 1px solid #e3e8ef;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    border-radius: 8px;
    font-size: 16px;
    margin-top: -20px !important;
    margin-bottom: 0px !important;
    color: var(--main-color);
    padding: 26px 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    background: transparent;
}

.auth-screens .radio label:hover {
    background: var(--main-color-light);
}

.auth-screens .radio label:hover .checker {
    box-shadow: inset 0 0 0 2px #EDAE49;
}

.auth-screens .radio .checker {
    display: none;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-right: 8px;
    box-shadow: inset 0 0 0 2px #ccc;
    transition: box-shadow 0.3s ease;
}

.auth-screens .radio label.active {
    background: #EDAE49;
    color: #000;
}

.auth-screens .radio label.active .checker {
    background: #EDAE49;
    color: #000;
    box-shadow: inset 0 0 0 2px #FFF;
}

@media screen and (max-width: 768px) {
    body{
        background: #042B56 !important;
    }

    .auth-screens form h3{
        font-size: 2.25rem;
        letter-spacing: 0.25px;
        margin-bottom: 0px;
    }

    .auth-screens form h6{
        font-size: 1.1rem;
        margin-bottom: 30px;
    }

    .auth-screens form hr{
        display: none;
    }

    .auth-screens .section-left-content h2 {
        font-size: 1.15rem;
    }

    .auth-screens .section-left {
        background: var(--main-color);
    }

    .auth-screens .section-left .bg-overlay{
        height: 100%;
        padding: 0rem .5rem .5rem .5rem;
    }

    .auth-screens .section-right{
        background: var(--main-color);
    }

    .auth-screens .section-right form{
        background: #FFF;
        margin-bottom: .5rem;
    }

    .auth-screens .bg-overlay{
        text-align: center;
    }

    .auth-screens .form-wrapper{
        padding: 0rem 1rem;
    }

    .auth-screens .auth-logo{
        margin-left: auto;
        margin-right: auto;
    }
}



.terms .content h2{
    color: black;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-bottom: 0.25rem;
}
.terms .content p{
    color: #666;
    font-size: 1rem;
    line-height: 1.8;
    /* margin-left: 20px; */
}

.privacy .content h2{
    color: black;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-bottom: 0.5rem;
}
.privacy .content p{
    color: #666;
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1rem;
}
.privacy .content ul{
    color: #666;
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1rem;
}