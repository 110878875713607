@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Oswald&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --main-bg: #1B1725;
  --main-color: #042B56;
  --alt-color: #3DD84E;
  --main-color-dark: #031E3A;
  --main-color-light: #4FA0F8;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

html,
body {
    background: #FFF;
    color: #121926;
    width: 100%;
    overflow-x: hidden;
    font-family: 'Outfit', sans-serif;
}

.heading {
    font-family: 'Oswald', serif;
}

.normal-font {
    font-family: 'Outfit', sans-serif;
}

.hide-in-sm {
    display: flex !important;
}

.show-in-sm {
    display: none !important;
}

.swal-modal * {
    text-align: center;
}

.btn:focus,
a:focus,
.form-control:focus {
    outline: 0 !important;
    box-shadow: none !important;
}

.btn {
    cursor: pointer;
}

.main-bg {
    background: #288162;
}

.bg-main {
    background: #042B56;
}

.text-main {
    color: #042B56;
}

.bg-main-alt {
    background-color: #3DD84E;
}

.text-main-alt {
    color: #3DD84E;
}

.bg-main-dark {
    background: #003052;
}

.text-main-dark {
    color: #003052 !important;
}

.bg-main-light {
    background: #668BBC !important;
}

.text-main-light {
    color: #668BBC !important;
}

.bg-main-alt-light {
    background-color: #F3AA9B !important;
}

.text-main-alt-light {
    color: #F3AA9B !important;
}

.bg-black {
    background: black !important;
}

.text-black {
    color: black !important;
}

.text-gray {
    color: lightgray !important;
}

.bg-white {
    background: white;
}

.text-white {
    color: white;
}

.border-main {
    border-color: #042B56 !important;
}

.border-main-alt {
    border-color: #3DD84E !important;
}

a {
    text-decoration: none !important;
}

.rounded-top-10 {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.rounded-bottom-10 {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.rounded-5 {
    border-radius: 5px;
}

.rounded-10 {
    border-radius: 10px;
}

.rounded-20 {
    border-radius: 20px;
}

.rounded-30 {
    border-radius: 30px;
}

.rounded-40 {
    border-radius: 40px;
}

.font-weight-300 {
    font-weight: 300;
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-500 {
    font-weight: 500;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-800 {
    font-weight: 800;
}

.font-weight-900 {
    font-weight: 900;
}

.font-105 {
    font-size: 105%;
}

.font-110 {
    font-size: 110%;
}

.font-95 {
    font-size: 95%;
}

.font-90 {
    font-size: 90%;
}

.font-85 {
    font-size: 85%;
}

.font-80 {
    font-size: 80%;
}

.form-group.row .col-md-3,
.form-group.row .col-md-4 {
    padding-top: 8px;
}

.pointer {
    cursor: pointer;
}

.tooltip{
    color: white;
    transition-duration: 300ms;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    line-height: 1.625;
    font-weight: 300;
    font-size: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    --tw-bg-opacity: 1;
    background-color: rgb(23 23 23 / var(--tw-bg-opacity));
    border-radius: 0.375rem;
    max-width: 12rem;
    width: 11rem;
    margin-bottom: 0.25rem;
    bottom: 100%;
    position: absolute;

}

@media only screen and (max-width:786px) {

    .hide-in-sm {
        display: none !important;
    }

    .show-in-sm {
        display: flex !important;
    }

    div.px-4 {
        padding-left: 0.8rem !important;
        padding-right: 0.8rem !important;
    }

    div.pl-5.pr-5 {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

}

.Toastify__toast-container--top-right {
    top: 0.65em !important;
    right: 0.65em !important;
}

.Toastify__close-button{
    display: none;
}

@media only screen and (max-width: 480px){

    .Toastify__toast{
        justify-content: center !important;
    }

    .Toastify__toast-container {
        top: auto !important;
        bottom: 0.65em !important;
        right: 0.65em !important;
        display: flex;
        flex-direction: column;
    }

    .Toastify__toast {
        padding: 0px 5px !important;
        border-radius: 50px !important;
        width: auto;
        margin: auto;
        min-height: 40px !important;
        max-height: 40px !important;
    }

    .Toastify__toast-container .Toastify__toast:not(:last-child){
        margin-bottom: 10px !important;
    }

    .Toastify__toast-body{
        flex: none !important;
        justify-content: center !important;
    }

    .Toastify__toast-body > div:last-child{
        flex: none !important;
    }

}