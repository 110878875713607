#wrapper {
    /*    background: #042B56;*/
}

#sidebar-wrapper {
    position: fixed;
    background: #042B56;
    height: calc(100vh - 1rem);
    margin-left: -12rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
    /*    border-right: 0.0625rem solid rgba(255, 225, 255, 0.25);*/
    border-radius: 10px;
}

#sidebar-wrapper .sidebar-heading {
    display: flex;
    justify-content: center;
    padding: 1rem .5rem;
    position: relative;
    transition: 0.25s;
}

#sidebar-wrapper .sidebar-heading img.logo {
    height: 9rem;
    max-width: 100%;
}

#sidebar-wrapper .sidebar-heading img.logo-sm {
    display: none;
}

#sidebar-wrapper .sidebar-heading h3 {
    padding-left: 10px;
    font-size: 1.25rem;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: white;
    font-weight: 800;
}

#sidebar-wrapper p {
    font-weight: 500;
    margin-bottom: 5px;
}

#sidebar-wrapper .px-2.d-flex {
    display: flex;
    flex-direction: column;
}

#sidebar-wrapper .border-top {
    /*    margin-top: 15px;*/
    /*    padding-top: 15px;*/
    border-top: none !important;
}

.nav-main {
    font-size: 14px;
    letter-spacing: .35px;
    border-radius: 6px;
    /*    color: #C2E5FF;*/
    color: rgba(255, 255, 255, 0.7);
    padding: 0.75rem;
    font-weight: 400;
    line-height: 1.5rem;
}

#sidebar-wrapper .nav-main svg {
    /* width: 30px; */
    width: 1.15rem;
    height: 1.15rem;
    text-align: center;
    font-size: .9rem;
    padding-left: 0px;
    color: rgba(255, 255, 255, 0.7);
    margin-right: 10px;
}

#sidebar-wrapper .nav-main svg path{
    /* stroke: rgba(255, 255, 255, 0.7); */
}

.toggled #sidebar-wrapper .px-2.d-flex .nav-main svg {
    width: 1.75rem;
}

.nav-main:hover {
    background: rgba(255, 255, 255, 0.1);
    color: #FFF;
}

#sidebar-wrapper .nav-main.active-link {
    background: #3DD84E;
    box-shadow: rgb(76 78 100 / 22%) 0px 2px 10px 0px;
    font-weight: 500;
}

#sidebar-wrapper .nav-main.active-link * {
    color: #000;
    stroke: #000;
}

#sidebar-wrapper .list-group p {
    color: #F9F9F9;
    font-weight: 400;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 3px;
    margin-bottom: 3px;
    padding-left: 0px;
}

#sidebar-wrapper .list-group .justify-content-between:hover {
    background: rgba(000, 000, 000, 0.4);
}

#sidebar-wrapper .list-group {
    width: 15rem;
    height: calc(100vh - 190px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
    border-radius: 15px;
}

#sidebar-wrapper .list-group::-webkit-scrollbar {
    width: 4px;
}

#sidebar-wrapper .list-group:hover::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
}

#sidebar-wrapper .list-group::-webkit-scrollbar-thumb {
    background: transparent;
}

#sidebar-wrapper .list-group::-webkit-scrollbar-thumb:hover {
    background: #f5f5f5;
}

#sidebar-wrapper .list-group .border-top {
    /*    padding-left: .5rem;*/
    /*    padding-right: .5rem;*/
}

#wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
}

#wrapper.toggled .nav-icon {
    display: block;
    text-align: right;
}

#wrapper.toggled .nav-main {
    padding: 15px;
}

#wrapper.toggled .nav-hide {
    display: none;
}

#wrapper.toggled .sub-menu p {
    display: none;
}

.nav-hide {
    display: flex;
    align-items: center;
}

.nav-icon {
    display: none;
}

#menu-toggle {
    display: none;
    background: transparent;
    border: 0;
    padding: 0;
    color: #CCC;
    font-size: 24px;
    position: absolute;
    top: 18px;
    right: 5px;
    cursor: pointer;
}

#menu-toggle svg{
    transform: rotate(180deg);
}

#menu-toggle-sm i {
    cursor: pointer;
    margin-right: 14px;
    font-size: 22px;
    padding-top: 7px;
    color: #f5f5f5;
}

/*
#menu-toggle i:before {
    content: "\f100";
}

.d-flex.toggled #menu-toggle i:before {
    content: "\f101";
}
*/

.modal .modal-header {
    background: #666CFF;
    color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.modal .modal-content,
.modal .modal-dialog {
    border-radius: 10px;
}

.modal .modal-content {
    border: 0px;
    background: transparent;
}

.modal .modal-body {
    background: white;
    padding-top: 3rem;
    padding-bottom: 3rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.modal .modal-header .modal-title {
    margin-bottom: 0px;
    font-weight: 600;
}

.icon-main {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    font-size: 1.25rem;
    line-height: 1;
    overflow: hidden;
    user-select: none;
    border-radius: 8px;
    color: #042B56;
    background-color: #C2E5FF;
}

#page-content-wrapper {
    min-width: 0;
    margin-left: auto;
    margin-right: auto;
    background: #f9f9fb;
    min-height: 100vh;
    /*    background: #FFF;*/
}

#page-content-wrapper nav.navbar {
    padding: 1rem 1.5rem;
    border-radius: 10px;
}

#page-content-wrapper section{
    padding: 1.25rem;
    background: white;
    border-radius: 10px;
    margin-bottom: 0.5rem;
    border: 1px solid #e5e7eb;
}

.btn-Active,
.btn-Inactive {
    transition: 0.5s;
}

.btn-Active:after {
    content: "Active";
    transition: 0.5s;
}

.btn-Inactive:after {
    content: "Inactive";
    transition: 0.5s;
}

.btn-Active:hover:after {
    content: "Inactive" !important;
}

.btn-Inactive:hover:after {
    content: "Active" !important;
}

.btn-Active:hover {
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-Inactive:hover {
    content: "Active" !important;
    background-color: #1e7e34;
    border-color: #1c7430;
}

.alert-main {
    background: #FFF;
    color: #042B56;
    border-radius: 8px;
    font-weight: 500;
}

.btn-main {
    border-radius: 8px;
    background-color: #042B56;
    color: white;
    transition: 350ms ease;
    font-size: 14px;
    line-height: 1em;
    font-weight: 400;
    text-align: center;
    letter-spacing: 0.06em;
    border: 1px solid #042B56;
    padding: 0.8rem 1.1rem;
}

.btn-main:hover {
    box-shadow: rgb(76 78 100 / 56%) 0px 6px 18px -8px;
    background-color: #031E3A;
    color: #FFF;
}

.btn-main-inv {
    border-radius: 8px;
    background-color: #FFF;
    color: #042B56;
    transition: 350ms ease;
    font-size: 14px;
    line-height: 1em;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.06em;
    border: 1px solid #042B56;
    padding: 0.8rem 1.1rem;
}

.btn-main-inv:hover {
    font-weight: 400;
    box-shadow: rgb(76 78 100 / 56%) 0px 6px 18px -8px;
    background-color: #042B56;
    color: #FFF;
}

.btn-cancel {
    display: flex;
    align-items: center;
    border-radius: .4rem;
    color: #dc3545;
    transition: 350ms ease;
    font-size: .875rem;
    font-weight: 500;
    letter-spacing: .3px;
    text-align: center;
    border: 1px solid #dc3545;
    padding: 0.5rem 1.5rem;
}

.btn-cancel:hover {
    box-shadow: rgb(76 78 100 / 56%) 0px 6px 18px -8px;
    background-color: #dc3545;
    color: #fff;
}

.btn-submit {
    display: flex;
    align-items: center;
    border-radius: .4rem;
    background-color: #042B56;
    color: white;
    transition: 350ms ease;
    font-size: .875rem;
    font-weight: 400;
    letter-spacing: .3px;
    text-align: center;
    border: 1px solid #042B56;
    padding: 0.65rem 1.5rem;
}

.btn-submit:hover {
    box-shadow: rgb(76 78 100 / 56%) 0px 6px 18px -8px;
    background-color: #031E3A;
    color: #FFF;
}

.btn-submit:disabled {
    opacity: 0.7;
}

.btn-submit svg, .btn-submit-alt svg, .btn-cancel svg, .btn-new svg{
    margin-left: 5px;
}

.btn-submit-alt {
    display: flex;
    align-items: center;
    border-radius: .4rem;
    background-color: #FFF;
    color: #042B56;
    transition: 350ms ease;
    font-size: .875rem;
    font-weight: 500;
    text-align: center;
    border: .0625rem solid #042B56;
    padding: 0.65rem 1.5rem;
}

.btn-submit-alt:hover {
    box-shadow: rgb(76 78 100 / 56%) 0px 6px 18px -8px;
    background-color: #f5f5f5;
    color: #042B56;
}

.btn-link {
    border-radius: 5px;
    background-color: #007bff;
    color: #FFF;
    transition: 350ms ease;
    font-size: 13px;
    line-height: 17px;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.03em;
    border: 1px solid #007bff;
    padding: 0.35rem 1rem;
}

.btn-link:hover {
    box-shadow: rgb(76 78 100 / 56%) 0px 6px 18px -8px;
    background-color: #042B56;
    color: #fff;
}

.btn-new {
    display: flex;
    align-items: center;
    border-radius: 8px;
    background-color: #FFF;
    color: #042B56;
    transition: 350ms ease;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.03em;
    border: 1px solid #042B56;
    padding: 0.35rem 1rem;
}

.btn-new:hover {
    box-shadow: rgb(76 78 100 / 56%) 0px 6px 18px -8px;
    background-color: #042B56;
    color: #fff;
}

.btn-main-inverse {
    border-radius: 4px;
    background-color: #FFF;
    color: #042B56;
    transition: 250ms ease;
    font-size: 14px;
    line-height: 1em;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.06em;
    border: 1px solid #042B56;
    padding: 0.8rem 1.1rem;
    display: flex;
}

.btn-main-inverse svg{
    margin-left: 10px;
    transition: 150ms ease;
}

.btn-main-inverse:hover {
    box-shadow: rgb(76 78 100 / 56%) 0px 6px 18px -8px;
    background-color: #042B56;
    color: #fff;
}

.btn-main-inverse:hover svg{
    margin-left: 15px;
}

.btn-main-alt {
    border-radius: 8px;
    background-color: var(--alt-color);
    color: #111;
    transition: 350ms ease;
    font-size: 14px;
    line-height: 1em;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.06em;
    border: 1px solid var(--alt-color);
    padding: 0.8rem 1.1rem;
}

.btn-main-alt:hover {
    box-shadow: rgb(76 78 100 / 56%) 0px 6px 18px -8px;
    background-color: #25BB37;
    color: #fff;
}

.btn-main-alt-inv {
    border-radius: 8px;
    background-color: #FFF;
    color: var(--alt-color);
    transition: 350ms ease;
    font-size: 14px;
    line-height: 1em;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.06em;
    border: 1px solid var(--alt-color);
    padding: 0.8rem 1.1rem;
}

.btn-main-alt-inv:hover {
    box-shadow: rgb(76 78 100 / 56%) 0px 6px 18px -8px;
    background-color: var(--alt-color);
    color: #fff;
}

.btn-main-delete {
    border-radius: 5px;
    background-color: #dc3545;
    color: white;
    transition: 350ms ease;
    font-size: 14px;
    line-height: 1em;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.06em;
    border: 1px solid #dc3545;
    padding: 0.600rem 1.1rem;
}

.btn-main-delete:hover {
    background-color: #c82333;
    color: white;
    border: 1px solid #c82333;
}

.btn-main-light {
    border-radius: 0.30rem;
    background-color: #FFF;
    border: 1px solid #f5f5f5;
    color: #333;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    letter-spacing: 0.06em;
    padding: 0.275rem 1rem;
    margin: 0.25rem 0rem;
    text-transform: capitalize;
}

.btn-main-light:hover {
    background-color: #f5f5f5;
    color: #000;
}

.btn-nav-user {
    display: flex;
    color: #FFF;
}

.btn-nav-user img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.btn-nav-user div {
    margin-left: 6px;
}
.btn-nav-user h6 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;
    letter-spacing: .35px;
}

.btn-nav-user a {
    font-size: 13px;
    color: rgba(255, 255, 255, 0.7);
}

.btn-main-nav {
    border-radius: 10px;
    background-color: #FFF;
    border: 1px solid #FFF;
    color: #042B56;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.06em;
    padding: 0.35rem 1rem;
    margin: 0.25rem 0rem;
    text-transform: capitalize;
    margin-right: 5px;
}

.btn-main-nav:hover {
    background-color: #000;
    color: white;
}

.btn-main-signout {
    border-radius: 10px;
    background: #bb2d3b;
    border: 1px solid #bb2d3b;
    color: white;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.06em;
    padding: 0.35rem 1rem;
    margin: 0.25rem 0rem;
}

.btn-main-signout:hover {
    background: #b02a37 !important;
    color: white;
}

.input-group-sm > .input-group-prepend > .input-group-text {
    padding: 0.35rem 0.5rem;
    font-size: .875rem;
    line-height: 1.5;
    font-weight: 500;
}

.input-group-sm > .custom-select,
.input-group-sm > .form-control:not(textarea) {
    height: calc(1.8em + 0.5rem + 2px);
}

label {
    margin-bottom: 0.5rem;
    font-size: .875rem;
    font-weight: 500;
}

form .form-control {
    width: 100%;
    display: block;
    border: 0.0625rem solid #e3e8ef;
    color: #121926;
    border-radius: 0.5rem;
    padding: 0.6rem 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    transition: all .3s;
}

.input-group .form-control {
    display: block;
    height: 100%;
    border: 0.0625rem solid #e3e8ef;
    color: #121926;
    border-radius: 0.5rem;
    padding: 0.6rem 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    transition: all .3s;
}

form input.form-control, form select.form-control {
    height: auto;
}

select.form-control, textarea.form-control {
    display: block;
    border: 0.0625rem solid #e3e8ef;
    color: #121926;
    border-radius: 0.5rem;
    padding: 0.7rem 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    transition: all .3s;
}

textarea.form-control {
    width: 100%;
}

form textarea.form-control {
    resize: none;
}

select.form-control:hover, textarea.form-control:hover, form input.form-control:hover, select.form-control:active, textarea.form-control:active, form input.form-control:active, select.form-control:focus, textarea.form-control:focus, form input.form-control:focus {
    border-color: #042B56;
    box-shadow: none;
    color: #121926;
}

select.form-control{
}

.table {
    margin-bottom: 0 !important;
}

table td p {
    text-align: left;
}

nav .page-head h4 {
    font-size: 22px;
    color: #FFF;
    font-weight: 500;
    letter-spacing: 0.25px;
    margin-bottom: 0px;
}

.page-head {
    font-size: 28px;
    color: #333;
    font-weight: 600;
    letter-spacing: 0.25px;
}

.text-delete,
.text-edit {
    cursor: pointer;
    opacity: 0.7;
    font-weight: 500;
    font-size: 14px;
    padding: 0px 2px 0px 2px;
}

.text-edit {
    color: #0062cc !important;
}

.text-delete {
    color: #c82333 !important;
}

.text-delete:hover,
.text-edit:hover {
    opacity: 1;
}

.btn-delete {
    cursor: pointer;
    opacity: 0.5;
}

.btn-delete:hover {
    opacity: 1;
}

.tooltip-inner {
    text-align: left;
    font-size: 90%;
}

.tooltip-inner hr {
    background: white;
    margin-top: 3px;
    margin-bottom: 3px;
}

.bs-tooltip-auto[x-placement^=top],
.bs-tooltip-auto[x-placement^=top] * {
    text-align: left !important;
}

div.shadow {
    box-shadow: rgb(76 78 100 / 22%) 0px 2px 10px 0px !important;
}

.input-group-sm > .input-group-append > .input-group-text {
    background: #f5f5f5;
    border: 1px solid #ced4da;
    border-top-right-radius: 7.5px !important;
    border-bottom-right-radius: 7.5px !important;
}

.input-group-sm > .input-group-prepend > .input-group-text {
    background: #f5f5f5;
    border: 1px solid #ced4da;
    border-top-left-radius: 7.5px !important;
    border-bottom-left-radius: 7.5px !important;
}

.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
    border-top-right-radius: 7.5px !important;
    border-bottom-right-radius: 7.5px !important;
}

.single-doc {
    position: relative;
}

.single-doc .badge-warning {
    position: absolute;
    right: -13px;
    top: -8px;
    padding: 0.4em 0.6em;
    letter-spacing: 0.25px;
}

.opac-50 {
    opacity: .5;
}

.opac-75 {
    opacity: .75;
}

.truncate {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.input-group{
    border-width: 1px;
    border-radius: 0.5rem;
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity));
    align-items: center;
    display: flex;
}

.input-group label{
    padding: 0rem 0.75rem;
    margin: 0px;
    min-height: 40px;
    display: flex;
    align-items: center;
}
.input-group .form-control{
    border-width: 0px;
    border-left-width: 1px;
}
.input-group .form-control:hover{
    border-color: #e3e8ef;
}

button[class^='react-scroll-to-bottom-'], button[class*='react-scroll-to-bottom-']{
    display: none;
}

@media (min-width: 768px) {

    #page-content-wrapper {
        margin-left: calc(15rem + .5rem);
        transition: .25s;
        /*        border-radius: 20px;*/
        /*        margin: .5rem .5rem .5rem 0rem;*/
    }

    .toggled #sidebar-wrapper .px-2.d-flex .nav-main svg {
        margin-left: auto;
    }

    #wrapper.toggled #sidebar-wrapper .sidebar-heading {
        justify-content: end;
        padding-top: 1.3rem;
        padding-bottom: 1.25rem;
    }

    #wrapper.toggled #sidebar-wrapper .sidebar-heading img.logo-sm {
        display: block;
        height: 2.5rem;
    }

    #wrapper.toggled #sidebar-wrapper .sidebar-heading img.logo {
        display: none;
    }

    #wrapper.toggled #page-content-wrapper {
        margin-left: calc(2.5rem + 0.5rem);
    }

    #sidebar-wrapper {
        margin-left: 0.5rem;
    }

    #wrapper.toggled #sidebar-wrapper {
        margin-left: -12rem;
    }
    
    #wrapper.toggled #sidebar-wrapper .list-group{
        height: calc(100vh - 5.5rem);
    }

    .toggled #sidebar-wrapper .px-2.d-flex {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .toggled #sidebar-wrapper .px-2.d-flex .nav-main {
        padding-right: 0px;
    }

}

@media (max-width: 768px) {


    .sm-width-50 {
        width: 50% !important;
    }

    #menu-toggle,
    #menu-toggle-sm {
        display: block;
    }

    .btn-main-nav {
        display: none;
    }

    #sidebar-wrapper {
        margin-left: -15rem !important;
        position: fixed;
        z-index: 10;
    }

    #sidebar-wrapper .list-group{
        padding-bottom: 50px;
        height: calc(100vh - 140px);
    }

    #wrapper.toggled #sidebar-wrapper {
        margin-left: 0.5rem !important;
        box-shadow: rgba(0,0,0,0.9) 3rem 0rem 20rem 3rem;
    }
    
    #wrapper div.nav-icon {
        display: none !important;
    }

    .sub-menu .list-group {
        display: block !important;
    }

    #wrapper.toggled .nav-hide {
        display: flex !important;
    }

    #page-content-wrapper{
        padding-bottom: 10px;
    }

    label {
        font-size: 80% !important;
    }

    .input-group{
        width: 100%;;
    }

    .input-group .form-control{
        width: 100%;
        flex: 1;
    }

}
