.table{
    width: 100%;
    border: 1px solid #e3e8ef;
    border-radius: 5px;
}

.table-wrapper .table-search{
    height: auto;
    min-height: 2rem;
    border: 0.0625rem solid #e3e8ef;
    color: #121926;
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    transition: all .3s;
}

.table-wrapper .table-search:focus-visible, .table-wrapper .table-search:focus, .table-wrapper .table-search:hover {
    border-color: #042B56;
    box-shadow: none;
    color: #121926;
    outline: none;
}

.table-wrapper select.rows{
    padding: 8px 8px;
    background-color: #f0f0f0;
    font-size: 12px;
    border-radius: 5px;
}

.table-wrapper .record-range{
    font-size: 14px;
    letter-spacing: .25px;
}

.table-wrapper .pagination{
    display: flex;
}

.table-wrapper .pagination button{
    padding: 5px 3px;
    background-color: #f0f0f0;
    border: 1px solid #FFF;
    font-size: 12px;
    border-radius: 5px;
}

.table-wrapper .pagination button.cur{
    padding: 5px 7px;
    letter-spacing: .25px;
    font-weight: 500;
}

.table-wrapper .table tr:hover {
    background-color: rgba(0,0,0,0.05);
}

.table-wrapper .table th {
    border-bottom: 1px solid #e1e1e1;
    font-size: .7rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: .45px;
    color: #555;
    padding: .75rem 1rem;
    text-align: left;
}

.table-wrapper .table td {
    color: #999 ;
    border-bottom: 0.0625rem solid #f0f0f0;
    font-weight: 500;
    font-size: .85rem;
    letter-spacing: normal;
    padding: .75rem 1rem;
}

.campaigns .table tr td:nth-child(1){
    font-size: 16px;
    letter-spacing: .35px;
    color: #333;
}

.tbl-details{
    width: 100%;
    border: 1px solid #f0f0f0;
}

.tbl-details th {
    border-bottom: 1px solid #f0f0f0;
    font-size: .75rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: .25px;
    color: #555;
    padding: .75rem 1rem;
    text-align: left;
}

.tbl-details td {
    color: #999;
    border-bottom: 0.0625rem solid #f0f0f0;
    font-weight: 500;
    font-size: .9rem;
    letter-spacing: normal;
    padding: .75rem 1rem;
}



@media screen and (max-width: 768px) {
    .table{
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table td, .table th{
        white-space: nowrap;
        vertical-align: middle;
    }
}