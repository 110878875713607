

section.players .tooltip{
    width: auto !important;
    z-index: 9999;
    padding: 0.25rem 0.5rem;
}

section.teams .tooltip{
    width: auto !important;
    z-index: 9999;
    padding: 0.25rem 0.5rem;
}